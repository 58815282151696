/*------------------------------------------------------------------
[Table of contents]

1. Minimum Width Media for 1600px
2. Minimum Width Media for 1400px
3. Maximum Width Media for 1600px
4. Maximum Width Media for 1490px
5. Maximum Width Media for 1299px
6. Maximum Width Media for 1199px
7. Maximum Width Media for 991px
8. Maximum Width Media for 767px

*/

/*------------------------------------------------------------------
1. Minimum Width Media for 1600px :: Starts
Handles Responsiveness above 1600px 
Devices Impact: Monitors and Laptops.
*/
@media screen and (min-width: 1600px) {
    .main-content {
        background-size: contain;
    }
}
/*------------------------------------------------------------------
Minimum Width Media for 1600px :: Ends
*/

/*------------------------------------------------------------------
2. Minimum Width Media for 1400px :: Starts
Handles Responsiveness above 1400px 
Devices Impact: Monitors and Laptops.
*/
@media screen and (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1200px
    }

}
/*------------------------------------------------------------------
Minimum Width Media for 1400px :: Ends
*/

/*------------------------------------------------------------------
3. Maximum Width Media for 1600px :: Starts
Handles Responsiveness below 1600px 
Devices Impact: Monitors, Laptops, Tablets in Landscape Mode, Tablets in Portiat Mode, and Mobile
*/
@media screen and (max-width: 1600px) {
    .about-banner .bottom-img .col-xl-4 {
        width: 25%;
    }

    .about-banner .about-info {
        margin-right: -60px;
        margin-left: 30px;
    }

    .about-banner .about-info h3 br {
        display: none;
    }

    /*homepage-1*/
    .banner-vectors .banner-vector-1 {
        left: -16%
    }

    .testimonial .testimonial-vector img {
        left: 2%;
    }


    /*homepage-3*/
    .hp-3 .banner-section .banner-vectors img.vector-1 {
        right: -16%
    }

    .hp-3 .banner-section .banner-vectors img.vector-3 {
        right: -3%
    }

    .hp-3 .banner-section .banner-vectors img.vector-5 {
        left: -12%
    }

    .hp-3 .banner-section .banner-vectors img.vector-6 {
        left: -16%
    }

    .banner-vectors .banner-vector-5 {
        left: -12%
    }

    /* .about-banner .about-images {
        margin-top: 60px;
        margin-left: 180px;
    }

    .about-banner .about-images .bottom-img img.top-4 {
        margin-left: 60px;
    } */
}

/*------------------------------------------------------------------
4. Maximum Width Media for 1490px :: Starts
Handles Responsiveness below 1490px 
Devices Impact: Monitors, Laptops, Tablets in Landscape Mode, Tablets in Portiat Mode, and Mobile
*/
@media screen and (max-width: 1490px) {
    .about-banner .about-top .top-img img.top-1 {
        width: 40%;
        right: calc(70% + 45px);
    }

    .about-banner .about-top .top-img img.top-2 {
        width: 70%;
    }

    #SmallTextIntheMiddle {
        margin-top: 50px!important;

    }

    .about-banner .about-images {
        margin-top: 60px;
    }

    .about-banner .about-images .bottom-img img.top-3 {
        /* margin-left: 100px; */
        /* width: 55%; */
    }

    .about-banner .about-images .bottom-img img.top-4 {

        width: 90%;

        margin-left: -30px;

    }

    .how-it-works .work-card.in-touch,
    .how-it-works .work-card.review {
        padding: 0px 50px 10px 50px!important;
    }

    .how-it-works .work-card.demo,
    .how-it-works .work-card.setup {
        padding: 0px 50px 10px 50px!important;

    }


    .features .feature-content .feature-info {
        padding: 0px 65px;
    }

    .try-onir .try-onir-img {
        position: absolute;
        top: -16%;
        right: 11%;
    }

    .about-para img {
        margin-left: 0px;
        width: 100%;
    }

    .about-para .para-content {
        left: 20px
    }



    /*blog-details section*/
    .blog-banner .middle-imgs {
        padding: 0;

    }

    .blog-banner .middle-imgs img {
        width: 520px;
    }

    /*homepage-1*/
    .banner-vectors .banner-vector-2 {
        right: -3%
    }

    .banner-vectors .banner-vector-5 {
        left: -8%;
    }

    .banner-vectors .banner-vector-4 {
        top: 65%
    }

    /*homepage-3*/
    .hp-3 .banner-section .banner-vectors img.vector-1 {
        right: -7%
    }

    .hp-3 .banner-section .banner-vectors img.vector-2 {
        top: 22%;
        right: -1%;
    }

    .hp-3 .banner-section .banner-vectors img.vector-5 {
        left: -9%;
    }

    .hp-3 .banner-section .banner-vectors img.vector-6 {
        left: -9%;
    }
}

/*------------------------------------------------------------------
5. Maximum Width Media for 1299px :: Starts
Handles Responsiveness below 1299px 
Devices Impact: Small Monitors, Small Laptops, Tablets in Landscape Mode, Tablets in Portiat Mode, and Mobile
*/
@media screen and (max-width: 1299px) {
    .testimonial .testimonial-circles {
        max-width: 1200px;
        height: 1200px;
    }

    .about-banner .about-images .bottom-img img.top-3 {
        /* margin-left: 75px;
        width: 57%; */
    }



    #SmallTextIntheMiddle {
        margin-top: 50px!important;

    }

    .about-banner .bottom-img .col-xl-4 {
        width: 33.33%;
    }

    .about-banner .about-images .bottom-img img.top-4 {
        width: 90%;
        margin-left: -40px;
    }

    .about-banner .about-images {
        margin-top: 50px;
    }

    .about-para .para-content {
        margin-top: 0;

    }



    /*homepage-3*/
    .hp-3 .banner-section .banner-vectors img.vector-1 {
        right: -5%;
    }

}

/*------------------------------------------------------------------
6. Maximum Width Media for 1199px :: Starts
Handles Responsiveness below 1199px 
Devices Impact: Small Monitors, Small Laptops, Tablets in Landscape Mode, Tablets in Portiat Mode, and Mobile
*/
@media screen and (max-width: 1199px) {
    .page-head {
        min-height: 550px;
    }

    .nav .nav-menu,
    .nav>.nav-button {
        display: none !important;
    }


    #SmallTextIntheMiddle {
        margin-top: 0px!important;

    }

    header .nav-mobile-menu {
        display: flex !important;
        position: fixed;
        top: 40px;
        right: 40px;
        z-index: 1010 !important; /* Ensure it stays on top */
    }

    #staff-Lk-mobile{
        display: flex!important;
        justify-content: end;
    }

    #only-for-desktop{
        display: none!important;
        justify-content: end;
    }

    .nav-menu.active {
        display: block !important;
        position: fixed;
        top: 100px; /* Adjust this value based on your layout */
        right: 0px;
        border-bottom-left-radius: 20px;
        text-align: right;
        padding: 10px; /* Adjust this value based on your design */
        z-index: 1020; /* Ensure it stays below the mobile menu */
        background-color: #eaeaea;

    }

    .features .feature-content ul li::before {
        display: none!important;
    }

    .nav-button.active {

    }


    .nav-menu.active .nav-links {
        display: block !important;
        flex-direction: column !important;

    }

    .nav-menu.active .nav-links .nav-link {
        color: black;
        font-weight: bold;

    }

    .nav-mobile-menu {
        height: 25px !important;
        width: 40px !important;
        z-index: 1010 !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: space-between !important;
        cursor: pointer !important;
    }

    #staff-Lk-mobile{
        display: flex!important;
        justify-content: end;
    }

    #only-for-desktop{
        display: none!important;
        justify-content: end;
    }

    .nav-mobile-menu .line {
        display: block !important;
        height: 4px !important;
        width: 100% !important;
        background: #ffffff !important;
    }





    .brand-partner img {
        width: 80%;
    }

    /*how it works*/
    .how-it-works {
        margin-top: 100px;
    }

    .how-it-works .work-center {
        width: 300px;
        height: 300px;
        margin-left: 0;
    }

    .how-it-works .working-content {
        margin-top: 100px;
    }



    .how-it-works .work-card.setup,
    .how-it-works .work-card.demo {
        padding: 0;
        padding-bottom: 25px;

    }

    .how-it-works .work-card.in-touch,
    .how-it-works .work-card.review {
        padding: 0;
        padding-bottom: 25px;
    }


    .how-it-works .work-center-img {
        left: 50%;
        transform: translateX(-50%);
    }




    /*features*/
    .features {
        margin-top: 100px;
    }

    .features .feature-content {
        margin-top: 100px;
    }

    .features .feature-content .feature-info {
        padding: 0px 50px;
    }

    .features .feature-content .section-title br {
        display: none;
    }

    .features .feature-content .section-subtitle br {
        display: none;
    }



    /*onir plans*/
    .onir-plans .plan-cards {
        display: table;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 45px;
    }


    .onir-plans .plan-card {
        padding: 60px 20px!important;
        width: 370px!important;
        margin-bottom: 50px;
    }

    .onir-plans .plan-card span {
        margin-bottom: 30px;
    }



    .onir-plans .plan-card .plan-amount.l-digit {
        padding: 0px 60px;

    }

    .onir-plans .plan-card button {
        /* padding: 0 20px; */
        margin-bottom: 40px;
        line-height: 65px;
    }



    /*faq*/
    .faq {
        padding-top: 50px !important;
    }

    .faq-content-wrap {
        padding: 0;
    }


    /*try-onir*/
    .try-onir {
        padding-top: 220px;
    }

    .try-onir .try-content {
        padding: 60px 60px 80px 80px;
    }

    .app-download div {
        height: 80px
    }

    .app-download div a span {
        margin-left: 5px;
    }

    .try-onir .try-onir-img {
        top: -14%;
        right: 6%;
    }

    .try-onir .try-onir-img img {
        width: 85%;
    }

    /*blog-section*/
    .onir-blogs .blog-cards .blog-card .blog-img img {
        width: 275px;
    }

    .blog-features .blog-feature-img img {
        width: 360px;
    }

    .blog-features .section-title {
        padding-left: 20px;
    }

    .blog-features .section-subtitle {
        padding-left: 20px;
        margin-bottom: 1rem;
        font-size: 0.85em;
    }

    .blog-features a {
        padding-left: 20px;
        margin-top: 0px;
    }

    .blog-features .blog-by {
        padding-left: 20px;
        padding-top: 10px;
    }

    .blog-details .onir-stories {
        padding-top: 100px;
    }

    .blog-banner {
        margin-bottom: 150px;
    }

    .leave-comment {
        margin-bottom: 150px;
    }

    /*homepage-1*/
    .banner-vectors .banner-vector-5 {
        left: 1%;
    }

    .banner-vectors .banner-vector-4 {
        top: 76%;
        left: 2%
    }


    /* homepage2*/
    .homepage-2 .banner-section h1 {
        font-size: 1.9em!important;
        line-height: 50px !important;
        padding-top: 0px !important;
    }

    .homepage-2 .banner-section .banner-buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .homepage-2 .banner-section .banner-buttons .get-started {
        font-size: 20px!important;
    }


    .homepage-2 .banner-section .banner-img img {
        width: 90% !important;
    }

    /*contact section*/
    .form-box .input-container input,
    .form-box .input-container select {
        width: 410px;
        height: 80px;
    }

    .form-box .contant-info {
        padding: 30px 40px;
        width: 260px;
        height: 160px;
    }



    .map .map-address {
        padding-left: 30px;
    }

    .map .map-img img {
        width: 100%;
    }

    /*about-section*/
    .about-banner .about-info {
        margin-left: 15px;
        margin-top: -70px;
        margin-right: 0;
    }

    .about-banner .about-info .section-title {
        font-size: 2.0em;
        line-height: 42px;
    }

    .about-banner .about-info .section-subtitle {
        line-height: 25px;
    }

    .about-banner .about-top .top-img {
        margin-left: 0;
    }

    .about-banner .about-top .top-img img.top-1 {
        /* margin-right: 25px; */
        /* margin-top: 30px; */
        /* width: 35%; */
        right: calc(80% + 30px);
    }

    .about-banner .about-top .top-img img.top-2 {
        /* margin-top: -77px; */
        width: 80%;
    }

    .about-banner .about-images {
        margin-top: 35px;
    }

    .our-work .work-content {
        padding-left: 0;
        margin-right: -70px;
        margin-bottom: 40px;
    }

    .our-work .work-image {
        padding-left: 0;
    }

    .our-work .work-img-sec {
        margin-left: 70px;
    }

    .our-work .work-img-sec img {
        width: 110%
    }

    .our-work .work-image img {
        width: 120%;
    }

    .about-para .para-content {
        margin-top: -30px;
    }

    .our-team .team-row {
        gap: 40px;
    }

    .about-focus .focus-cards {
        gap: 45px
    }

    /*contact-section*/
    .office .office-content-sec {
        padding-left: 0;
    }

    .office .office-img img {
        margin-left: 0;
        width: 90%;
    }

    .office .office-images-sec img.office-2 {
        margin-left: 0;
        width: 100%;
    }

    /*blog-details-section*/
    .blog-banner .top-img img {
        width: 100%
    }

    .blog-banner .middle-imgs img {
        width: 430px;
    }

    .blog-comments .existing-comment {
        padding: 0 110px;
    }

    /*homepage-3*/
    header.homepage-3 .nav-menu .login {
        margin-top: 50px;
    }

    .hp-3 .banner-section {
        overflow-x: hidden;
    }

    .hp-3 .banner-section::before {
        width: 900px;
        height: 900px;
        top: 23%;
    }

    .hp-3 .mobile-ss::before {
        width: 900px;
        height: 900px;
    }

    .hp-3 .how-it-works .working-content .work-card {
        padding: 12px 25px;
    }
}

/*------------------------------------------------------------------
7. Maximum Width Media for 991px :: Starts
Handles Responsiveness below 991px
Devices Impact: Tablets in Portiat Mode and Mobile
*/
@media screen and (max-width: 991px) {
    .page-head h2 {
        font-size: 70px!important;
        z-index: 120;
    }

    .main-content.RequestPlan .contant-info, .main-content.contact-sec .contant-info {
        display: none!important;
    }

    /*how-it-works*/
    .how-it-works .section-subtitle {
        padding-bottom: 50px;
    }

    #SmallTextIntheMiddle {
        margin-top: 0px!important;

    }

    .how-it-works p {
        font-size: 15px;
    }

    .how-it-works .working-content {
        margin-top: 400px;
        padding-top: 100px;

    }

    .how-it-works .work-card.in-touch,
    .how-it-works .work-card.review {
        justify-content: center!important;
        align-items: center!important;
        text-align: center!important;

    }



    .how-it-works .work-card.setup,
    .how-it-works .work-card.demo {
        justify-content: center!important;
        align-items: center!important;
        text-align: center!important;
    }

    .how-it-works .work-card .steps-para {
        width: 250px;
        text-align: center;
    }

    .how-it-works .work-center {
        display: block;
    }

    .how-it-works .work-center-img {
        top: -43%;
        left: 50%;
        transform: translateX(-50%);
    }

    .how-it-works h6 {
        font-size: 20px;
    }

    .how-it-works .col-lg-4:nth-child(2) {
        order: -1;
    }
    /*features*/
    .features .title-top {
        text-align: center;
        font-size: 0.5em;
    }

    .features .feature-content .section-title {
        text-align: center;
        font-size: 1.2em;
        padding: 0 5px;
    }

    .features .feature-content .section-subtitle {
        text-align: center;
    }

    .features .feature-content ul {
        text-align: center;

    }




    .features .feature-content ul li {
        margin-bottom: 5px!important;
    }


    .features .app-download {
        align-items: center;
    }

    .feature-content.boost-productivity .row {
        flex-direction: column-reverse;
    }

    /*why-onir*/
    .why-onir {
        margin-top: 130px;
    }

    .why-features-cards {
        margin-top: -9px;
        margin-left: 0px;
    }

    .why-onir .why-cards .why-card {
        margin-bottom: 40px;
    }

    .why-onir .why-cards p {
        padding: 0 130px;
    }

    .why-features-cards .why-feature {
        position: relative;
        top: -40%;
        margin: auto;
        margin-bottom: 50px;
    }

    .why-onir .why-cards {
        margin-bottom: 0;
    }

    /*testimonial*/
    .testimonial {
        margin-top: 0;
    }

    .testimonial .testimonial-circles .testimonial-imgs img {
        width: 11%;
    }

    .testimonial .testimonial-circles {
        max-width: 750px;
        height: 750px;
    }

    .testimonial .testimonial-circles:before,
    .testimonial .testimonial-circles:after {
        width: calc(100% - 180px);
        height: calc(100% - 180px);
    }

    .testimonial .testimonial-circles:after {
        width: calc(100% - (170px * 2));
        height: calc(100% - (170px * 2));
    }

    .testimonial .testimonial-reviews .review img {
        width: 24%;
    }

    .testimonial .testimonial-reviews .review p br {
        display: none;
    }

    .testimonial .testimonial-reviews .review p {
        width: 250px;
        padding-top: 25px;
        padding-bottom: 20px;
        line-height: 20px;
    }

    .onir-plans .container,
    .onir-stories .container {
        padding: 0;
    }

    .onir-stories .stories-card .story-card .story-content {
        padding: 20px;
        width: 245px;
    }

    .onir-stories .stories-card .story-card .story-content p {
        font-size: 0.935em;
        line-height: 20px;
        margin-bottom: 5px;
    }

    .owl-item .plan-card {
        transform: scale(0.9);
    }

    .owl-item.active.center .plan-card {
        transform: scale(1);
    }


    /*try-onir*/
    .try-onir .try-content {
        padding: 60px 60px 80px 55px;
    }


    .try-onir .try-content .app-download {
        flex-direction: column;
    }

    .try-onir .try-onir-img {
        top: 22%;
        right: -3%;
    }

    .try-onir .try-onir-img img {
        width: 75%;
    }

    /*subscriber-input*/
    .subscribe-input .input-container button {
        margin-left: 18px
    }

    /*footer*/
    .footer {
        text-align: center;
        padding-bottom: 90px;
        padding-top: 100px;
    }

    .footer h5 {
        display: none;
    }

    .footer .footer-start {
        margin-bottom: 50px;
    }

    .footer .footer-socials {
        margin-top: 50px;
    }

    .footer .footer-socials .social-icon {
        justify-content: center;
    }

    .footer .footer-socials .app-download {
        align-items: center;
    }

    /*homepage-1*/
    .banner-vectors .banner-vector-3 {
        top: 101%
    }

    .features .feature-vectors img.feature-vector-1 {
        top: 12%;
        left: 64%;
    }

    .features .feature-vectors img.feature-vector-2 {
        top: 25%;
        left: 18%;
    }

    .features .feature-vectors img.feature-vector-3 {
        bottom: 24%;
        right: 19%;
    }

    .features .feature-vectors img.feature-vector-4 {
        right: 75%;
        bottom: 24%;
    }

    .testimonial .testimonial-vector img {
        left: 16%;
        z-index: 1;
        bottom: 8%;
    }

    .subscribe-input .bottom-vector img {
        top: 10%;
        left: 0%;
        width: 33px;
    }

    /*homepage2*/
    /*banner*/
    .homepage-2 .banner-section h1 {
        text-align: center;
    }

    .homepage-2 .banner-section h1 span {
        display: block;

    }

    .homepage-2 .banner-section .banner-buttons {
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .homepage-2 .banner-section .banner-buttons .get-started {
        font-size: 20px!important;
    }


    .homepage-2 .banner-section .banner-img {
        text-align: center;

    }



.homepage-2 .banner-section {
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 150px !important;

    padding-bottom: 200px !important;
}


    /*why-onir*/
    .homepage-2 .why-onir .why-onir-content {
        margin-top: -270px;
    }

    /*blog-section*/
    .blog-features .blog-feature-img {
        text-align: center;
        margin-bottom: 30px;
    }

    .blog-features .section-title,
    .blog-features .section-subtitle,
    .blog-features a,
    .blog-features .blog-by {
        padding-left: 0;
    }

    .blog-features .section-subtitle {
        margin-bottom: 20px;
    }

    .blog-features .blog-by {
        padding-top: 35px;
    }

    /*contact scetion*/
    .form-box .contant-info {
        padding: 35px 15px;
        width: 100%;
        justify-content: center;
    }

    .form-box .contant-info i {
        font-size: 20px;
    }

    .form-box .contant-info p {
        font-size: 20px;
        margin-top: 12px;
    }

    .form-box .input-container {
        margin-bottom: 35px;
    }

    .form-box .input-container input,
    .form-box .input-container select {
        width: 100%;
        height: 70px!important;
        font-size: 15px!important;

    }

    .form-box .form-box-bottom {
        margin-bottom: 30px;
    }

    .office .office-content-mobile {
        display: block;
    }

    .office .office-content-sec {
        display: none;
    }

    .office .office-img img {
        width: 100%;
    }

    .office .office-img img {
        margin-top: 200px;
    }

    .office .office-images-sec img.office-2 {
        margin-top: 20px;
    }

    .map .row {
        flex-direction: column-reverse;
    }

    .map .map-img {
        margin-left: 0px;
        border-radius: 20px;
        overflow: hidden;
    }

    .map .map-address {
        justify-content: center;
    }

    .map .map-address h6 {
        margin-top: 28px;
    }

    /*about-section*/
    .about-banner .about-info {
        display: none;
    }

    .about-banner .about-images {
        overflow-x: hidden;
    }

    .about-banner .container-fluid {
        padding-right: 0;
    }

    .about-banner .about-top .top-img {
        justify-content: center;
        gap: 30px;
    }

    .about-banner .about-images .bottom-img img.top-3 {
        margin-left: 0;
    }

    .about-banner .about-images .bottom-img img.top-4 {
        width: 85%;
        margin-left: 10px;
    }

    .about-banner .about-top .top-img img.top-1 {
        width: 36%;
        position: static;
    }

    .about-banner .about-top .top-img img.top-2 {
        width: 50%;
    }

    .about-banner .about-info-mobile {
        display: block;
        margin-top: 50px;
        padding: 0 25px;
    }

    .about-banner .about-info-mobile .section-subtitle {
        text-align: left;
    }

    .about-banner .about-info-mobile .section-title {
        text-align: left;
    }

    .about-mission .section-title br {
        display: none;
    }

    .our-work .work-content {
        display: none;
    }

    .our-work .work-content-mobile {
        display: block;
        padding-right: 15px;
    }

    .our-work .work-content-mobile .section-title {
        text-align: left;
    }

    .our-work .work-content-mobile .section-subtitle {
        text-align: left;
    }

    .our-work .work-image {
        margin-top: 150px;
    }

    .our-work .work-img-sec {
        margin-left: 40px;
        margin-top: 20px;
    }

    .about-para .para-content {
        margin-top: 25px;
    }

    .about-para .section-title {
        line-height: 50px;
        margin-bottom: 20px;
    }

    .about-para .section-subtitle {
        line-height: 25px;
    }

    .our-team .team-row {
        flex-wrap: wrap;
        margin-top: 40px;
    }

    .about-focus .focus-cards {
        gap: 30px;
    }

    .about-focus .focus {
        padding: 50px 30px;
        padding-bottom: 155px;
    }

    .about-focus .focus-cards.row-2 {
        padding: 0 30px;
    }

    .about-focus .focus-cards .focus-card {
        padding: 40px 30px 18px;
    }

    .our-team .team-row .team-card .social {
        font-size: 20px;
        gap: 20px
    }

    .about-para {
        margin-bottom: 100px;
    }

    /*blog-details section*/
    .blog-banner .middle-imgs img {
        width: 315px;
    }

    .blog-banner .blog-slogan::before {
        top: -5%
    }

    .blog-comments .existing-comment {
        padding: 0;
    }

    /*homepage-3*/
    .hp-3 .how-it-works .working-content {
        flex-wrap: wrap;
    }

    .hp-3 .banner-section::before {
        width: 695px;
        height: 695px;
        top: 16%;
    }

    .hp-3 .banner-section {
        padding-top: 120px;
    }

    .hp-3 .banner-section h1 {
        line-height: 90px;
    }

    .hp-3 .banner-section .banner-vectors img.vector-4 {
        top: 35%;
    }

    .hp-3 .brand-partner {
        margin-top: 150px;
    }

    .hp-3 .how-it-works .working-content {
        margin-top: 50px;
    }

    .hp-3 .features {
        margin-bottom: 100px;
    }

    .hp-3 .mobile-ss::before {
        width: 785px;
        height: 785px;
        top: -7%
    }

    .hp-3 .mobile-ss .screenshot img {
        margin-top: 0 !important;
    }

    .hp-3 .testimonial {
        padding-bottom: 100px;
    }

    .hp-3 .mobile-ss {
        height: 900px;
        overflow-x: hidden;
    }


    .hp-3 .mobile-ss::before {
        top: 1%
    }

    .hp-3 .mobile-ss .screenshot img {
        margin-top: 110px !important;
        width: 240px !important;
        height: 490px !important;
    }

    .hp-3 .how-it-works .working-content .work-card img {
        width: auto;
    }
}

/*------------------------------------------------------------------
8. Maximum Width Media for 767px :: Starts
Handles Responsiveness below 767px
Devices Impact: Mobiles
*/
@media screen and (max-width: 767px) {
    body {
        font-size: 15px;
    }

    #SmallTextIntheMiddle {
        margin-top: 0px!important;

    }

    .page-head h2 {
        font-size: 50px!important;
        text-align: center;
        z-index: 120;

    }

    .section-title {
        line-height: 41px;
        font-size: 30px!important;
    }

    .section-subtitle {
        line-height: 20px;
    }

    .app-download {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    /*page-head*/

    /*banner*/
    .banner-section h1 {
        font-size: 3.3em;
        line-height: 55px;
    }

    .banner-section p {
        margin-bottom: 20px;
    }

    .brand-partner {
        margin-top: 100px;
    }

    .brand-partner>div>h3 {
        font-size: 40px;
    }

    /*how-it-works*/

    .how-it-works .section-subtitle {
        padding: 0 40px;
    }

    .how-it-works .section-subtitle br {
        display: none;
    }

    .how-it-works>div>h3 {
        font-size: 30px;
    }

    .how-it-works .work-center-img img {
        width: auto;
    }

    .how-it-works .work-center-img {
        top: -52%;
        left: 50%;
        transform: translateX(-50%);
    }

    .how-it-works .working-content {
        margin-top: 575px;
        padding-top: 170px;
    }

    .how-it-works .work-card .steps-para {
        padding: 0 0px;
        font-size: 14px;
        text-align: center;

    }

    .how-it-works .how-footer p {
        padding: 0 50px;
    }

    /*features*/

    .features .feature-content {
        margin-top: 50px;
    }

    .features .feature-content .feature-info {
        padding: 0px;
    }

    .features .feature-content .feature-info .section-title,
    .features .feature-content .feature-info .section-subtitle {
        padding: 0 5px;
    }

    .features .feature-content ul li span{
        margin-left: 0!important;
        font-size: 14px;
        text-align: center;
    }


    .features>div>h3 {
        font-size: 30px;
    }

    /*why-onir*/
    .why-onir {
        margin-top: 100px;
    }

    .why-onir .why-onir-content {
        padding: 40px 25px;
    }

    .why-onir .section-subtitle br {
        display: none;
    }

    .why-onir .why-cards {
        margin: 45px 0;
    }

    .why-onir .why-cards h6 {
        font-size: 20px;
    }

    .why-onir .why-cards p {
        padding: 0 45px;
        font-size: 14px;
    }

    .why-features-cards .why-feature h3 {
        font-size: 35px;
    }

    .why-features-cards .why-feature div {
        font-size: 20px;
    }

    /*testimonial*/


    .testimonial .testimonial-circles {
        border: none;
        /* width: fit-content; */
        margin: 0;
        margin-top: 120px;
        /* max-width: 96vw; */
        height: 96vw;
    }

    .testimonial .testimonial-circles:before,
    .testimonial .testimonial-circles:after {
        width: calc(100% + 100px);
        height: calc(100% + 100px);
        /* left: 27%; */
    }

    .testimonial .testimonial-circles:after {
        width: calc(100% - 50px);
        height: calc(100% - 50px);
    }

    .testimonial-imgs {
        display: none;
    }

    .testimonial .testimonial-circles .owl-carousel .owl-dots {
        margin-top: 25px
    }

    /*onir-plans*/
    .onir-plans {
        margin-top: 50px !important;
    }

    .onir-plans .plan-card .plan-name {
        font-size: 20px;
    }

    .onir-plans .plan-card span {
        font-size: 15px;
    }

    .onir-plans .plan-card ul li {
        font-size: 15px !important;

    }

    .onir-plans .plan-card .plan-features li {
        justify-content: center !important;
    }

    /*onir-stories*/
    .onir-stories {
        padding-top: 100px;
    }

    .onir-stories .section-subtitle {
        padding: 0 15px;
    }

    /*faq-section*/
    .faq .section-title {
        margin-bottom: 35px;
    }

    .faq .qa-content {
        flex-direction: column;
        gap: 18px
    }

    .faq .qa-content .question {
        font-size: 20px;
    }

    .faq .qa-content .answer {
        font-size: 14px;
    }

    .faq .qa-content .answer::before {
        display: none;
    }

    /*subscriber-input*/
    .subscribe-input .input-container {
        border: none;
        padding-bottom: 0;
    }

    .subscribe-input .input-container input {
        max-width: 325px;
        width: 100%;
        min-height: 85px;
        font-size: 20px
    }

    .subscribe-input .input-container button {
        margin-top: 35px;
        margin-left: 0;
        max-width: 320px;
        width: 100%;
        font-size: 20px;
        padding: 0 75px;
        line-height: 85px;
    }

    .subscribe-input {
        padding: 95px 15px 0px;
    }

    .try-onir .try-content .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .try-onir .try-content .col-lg-7,
    .try-onir .try-content .col-lg-5 {
        width: 100%;
        text-align: center;
    }
    .try-onir .try-content .col-lg-5 {
        margin-bottom: 20px;
    }
    .try-onir .try-content .section-title {
        margin-top: 20px;
    }

    /*footer*/
    .footer {
        font-size: 20px;
    }

    .footer .footer-socials h6 {
        font-size: 20px;
    }

    .footer .footer-socials .social-icon {
        font-size: 25px;
    }

    /*blog section*/
    .page-head .input-container input {
        width: 350px;
        padding: 15px 44px;
        font-size: 20px;
    }

    .page-head .input-container button {
        display: none;
    }

    .page-head .fa-magnifying-glass:before,
    .fa-search:before {
        font-size: 20px;
    }

    .page-head .page-vectors img.page-vector-2 {
        left: -3%
    }

    .page-head .page-vectors img.page-vector-3 {
        top: 40%;
        left: 0%;
    }

    .page-head .page-vectors img.page-vector-1 {
        top: 69%;
        right: 1%;
    }

    .page-head .input-container input::placeholder {
        display: none;
    }

    .blog-features {
        margin-bottom: 100px;
    }

    .blog-features .blog-feature-img {
        padding-left: 0;
    }

    .blog-features .blog-feature-img img {
        width: 325px;
        height: 325px;
    }

    .onir-blogs .blog-cards {
        padding: 0;
    }

    .onir-blogs .blog-cards .blog-card .blog-img img {
        width: 325px;
    }

    /*homepage2*/
    /*banner-section*/
    .homepage-2 .banner-section h1 {
        font-size: 1.9em!important;
        line-height: 50px !important;
        padding-top: 0px !important;

    }

    .homepage-2 .banner-section .banner-buttons {
        margin-top: 20px!important;
    }

    .homepage-2 .features {
        padding-bottom: 20px !important;
        padding-top: 150px !important;
    }

    /*why-onir*/
    .homepage-2 .why-onir .why-onir-content {
        margin-top: -190px;
    }

    /*contact section*/
    .form-box {
        padding: 30px;
    }

    .form-box .contant-info {
        margin-bottom: 35px;
    }

    .form-box>p {
        margin-bottom: 35px;
    }

    .form-box button {
        width: 270px;
        padding: 20px 44px;
    }

    .office {
        overflow-x: hidden;
    }

    .office .office-content-mobile .section-title {
        text-align: left;
    }

    .office .office-content-mobile .section-subtitle {
        text-align: left;
    }

    .office .office-img img {
        margin-top: 144px;
        width: 163px;
        margin-left: -38px;
    }

    .office .office-images-sec img.office-2 {
        margin-top: 20px;
        margin-left: 25px;
        width: 325px;
    }

    .map {
        margin-bottom: 50px;
    }

    .map .map-address h6 {
        text-align: center;
        font-size: 20px;
        font-weight: 700;
    }


    /*about-section*/
    .about-banner {
        margin-bottom: 90px;
    }

    .about-banner .container {
        padding-right: 0;
    }

    .about-banner .about-images {
        overflow-x: hidden;
        margin-top: 30px;

    }

    .about-banner .about-top .top-img {
        margin-left: -70px;
    }

    .about-banner .about-top .top-img img.top-1 {
        width: 163px;
        height: 163px;
        margin-top: -60px;
    }

    .about-banner .about-top .top-img img.top-2 {
        width: 218px;
        height: 218px;
        margin-top: -115px;
    }

    .about-banner .about-info-mobile {
        padding-right: 40px;
        padding-left: 0;
    }

    .about-banner .about-images .bottom-img img.top-3 {
        margin-left: 0;
        width: 135px;
        height: 135px;
    }

    .about-banner .about-images .bottom-img img.top-4 {
        width: 270px;
        margin-left: 30px;
    }

    .about-mission {
        margin-bottom: 90px;
        padding-left: 0;
    }

    .about-mission .section-subtitle {
        padding-right: 15px;
    }

    .about-focus .focus .section-title {
        font-size: 30px!important;
        line-height: 41px;
    }

    .about-focus .focus-cards {
        margin-top: 30px;
    }



    .our-work {
        margin-bottom: 90px;
    }

    .our-work .container {
        padding-right: 0;
    }

    .our-work .work-image {
        margin-top: 100px;
        margin-left: -105px;
    }

    .our-work .work-content-mobile .section-subtitle {
        padding-right: 30px;
    }

    .our-work .work-image img {
        width: 220px;
    }

    .our-work .work-img-sec {
        margin-left: 12px;
        overflow-x: hidden;
    }

    .our-work .work-img-sec img {
        width: 330px;
    }

    .about-para .section-subtitle {
        line-height: 20px;
    }

    .about-para {
        margin-bottom: 75px;

    }

    .about-para .para-content {
        left: 0
    }

    .our-team {
        margin-bottom: 90px;
    }

    .our-team .team-row {
        gap: 35px;
        margin-top: 35px;
    }

    .ask-question {
        margin-bottom: 50px;
    }

    .about-focus {
        margin-bottom: 90px;
    }

    .about-focus .focus-cards {
        flex-direction: column;
    }

    /*blog-details section*/
    .blog-banner {
        margin-bottom: 100px;
    }

    .blog-banner .top-img-mobile {
        display: block;
        margin-top: -70px;
        margin-bottom: 30px;

    }

    .blog-banner .top-img-mobile img {
        width: 325px;
        height: 325px;
        border-radius: 20px;

    }


    .blog-banner .top-img {
        display: none;
    }

    .blog-banner p {
        padding: 0;
    }

    .blog-banner p:first-child {
        margin-bottom: 10px;
    }

    .blog-banner .middle-imgs img {
        width: 154px;
        height: 154px;
        margin-bottom: 30px;
    }

    .blog-banner .blog-slogan {
        margin-top: 100px;
    }

    .blog-banner .blog-slogan::before {
        left: 10%;
        top: 4%;
    }

    .blog-banner .blog-slogan h3 {
        font-size: 25px;
        line-height: 35px;
        padding: 60px 30px 40px;
    }

    .leave-comment .section-title {
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .leave-comment p {
        margin-bottom: 30px;
        padding: 0 30px;
    }

    .leave-comment {
        margin-bottom: 100px;
    }

    .leave-comment .form-box {
        padding: 0;
    }

    .leave-comment .form-box button {
        width: 325px;
    }

    .blog-comments .existing-comment {
        gap: 35px;
        margin-bottom: 25px;
    }

    .blog-comments .existing-comment img {
        width: 75px;
    }

    .blog-comments .existing-comment p span {
        display: none;
    }

    .blog-details .onir-stories {
        margin-bottom: 90px;
    }

    /*homepage-1*/
    .banner-vectors .banner-vector-2 {
        display: none;
    }

    .banner-vectors .banner-vector-3 {
        top: 74%;
        width: 43px;
        right: 1%;
    }

    .banner-vectors .banner-vector-4 {
        top: 102%;
        left: 14%;
        width: 88px;
    }

    .banner-vectors .banner-vector-5 {
        left: -1%;
        bottom: 9%;
    }

    .hp-1 .banner-section {
        overflow-x: hidden;
    }

    .hp-1 .banner-section .app-download {
        margin-bottom: 50px;
    }

    .brand-partner .logo-imgs img {
        width: 150px;
    }

    .how-it-works .work-vector img.vector-1 {
        top: -50%;
        width: 45px;
    }

    .how-it-works .work-vector img.vector-2 {
        top: -29%;
        left: -12%;
        width: 43px;
    }

    .features {
        overflow-x: hidden;
    }

    .features .feature-vectors img.feature-vector-1 {
        top: 9%;
        left: 79%;
    }

    .features .feature-vectors img.feature-vector-2 {
        top: 24%;
        left: -2%;
    }

    .features .feature-vectors img.feature-vector-3 {
        bottom: 27%;
        right: -11%;
    }

    .features .feature-vectors img.feature-vector-4 {
        right: 94%;
        bottom: 26%;
    }

    .testimonial .testimonial-vector img {
        bottom: 17%;
    }

    .onir-plans .plan-vectors img.vector-1 {
        top: 26%;
        left: -7%;
        width: 60px;
    }

    .onir-plans .plan-vectors img.vector-2 {
        display: none;
    }

    .onir-stories .story-vector img {
        top: 0
    }

    .try-onir .try-vectors img.vector-1 {
        top: -23%;
        right: -11%;
    }

    .try-onir .try-vectors img.vector-2 {
        top: -21%;
        left: -3%;
    }

    /*homepage-3*/
    .hp-3 .banner-section .container {
        padding: 0;
    }

    .brand-partner p {
        margin-bottom: 20px;
    }

    .hp-3 .banner-section h1 {
        line-height: 55px;
        padding: 0 20px;
        padding-top: 50px;
    }

    .hp-3 .banner-section h1 br {
        display: none;
    }

    .hp-3 .banner-section .banner-vectors img.vector-6 {
        display: none;
    }

    .hp-3 .banner-section .banner-vectors img.vector-3 {
        display: none;
    }

    .hp-3 .banner-section .banner-vectors img.vector-1 {
        display: none;
    }

    .hp-3 .banner-section .banner-image img {
        width: 325px;
    }

    .hp-3 .banner-section .banner-vectors img.vector-4 {
        top: 67%;
        width: 100px;
        left: -8%;
    }

    .hp-3 .features {
        overflow-x: hidden;
    }

    .hp-3 .banner-section .banner-vectors img.vector-7 {
        top: 32%;
        left: -11%;
        width: 110px;
    }

    .hp-3 .banner-section .banner-vectors img.vector-5 {
        top: 22%;
        left: 2%
    }

    .hp-3 .how-it-works .working-content .work-card .work-steps {
        font-size: 1.325em;
    }

    .hp-3 .how-it-works .working-content .work-card {
        margin-bottom: 50px;
    }

    .testimonial .testimonial-circles:after {
        background: none;
    }

    /*modal section*/

    .modal.show .modal-dialog {
        margin: 1em;


    }

    .modal-header .modal-title {
        font-size: 2.0em;
    }

    .modal-header {
        padding: 40px 40px 0;
    }

    .modal-body {
        padding: 40px 40px;
    }

    .modal .form-container .btn {
        margin: 12px auto;
        padding: 13px 44px;
        font-size: 20px;
    }

    .modal .input-container input {
        font-size: 15px;
    }

    .modal .input-container {
        margin-bottom: 15px;
    }

    .another-way {
        margin-top: 30px;
        margin-bottom: 24px;
    }

    .modal-header .btn-close {
        top: 40px;
    }


}
/*------------------------------------------------------------------
Maximum Width Media for 767px :: Ends
*/