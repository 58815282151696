.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.alert-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 80%;
}

.alert-picture {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.alert-title {
    font-size: 24px;
    margin: 20px 0;
}

.alert-message {
    font-size: 16px;
    margin: 20px 0;
}

.alert-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.alert-button {
    padding: 10px 20px;
    background-color: #858585;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.alert-button:hover {
    background-color: #656565;
}

.black-button {
    background-color: #2f2e2e;
    color: white;
}

.black-button:hover {
    background-color: #000000;
}