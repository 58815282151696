
@font-face {
    font-family:"Marker Felt 1";
    src: url("../files/MarkerFelt.ttc") format("truetype");
}

@font-face {
    font-family:"Avenir Next bold";
    src: url("../files/Avenir Next.ttc") format("truetype");
}

@font-face {
    font-family:"impact";
    src: url("../files/Impact.ttf") format("truetype");
}


.Home {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    padding-top: 30px;
    padding-bottom: 50px;
    background-color: #EAEAEA;
    align-content: center;
    align-items: center;
}


#exiteblock {
    margin-top: 15px;
    width: 300px;
}


#exiteBtn {
    font-family: codenext;
    font-size: 16px;
    padding: 1px 6px;
    color: white;
    border-radius: 5px;
    border: solid 0px;
    background-color: black;
}

#rusoil {
    font-family: codenext;
    font-size: 80px;
    color: #171616;
}

.employeeData {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;

}

.maindata {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

#competition {
    display: none;
    margin-bottom: 20px;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: codenext;
    font-size: 25px;
    color: goldenrod;
    text-align: center;

}

#competitionCondition {
    margin-top: 10px;
    margin-bottom: 10px;
    white-space: pre-line;
    margin-right: 20px;
    font-family: codenext;
    font-size: 18px;
    color: green;
    text-align: center;

}

#competitionRewardTitle {
    margin-top: 10px;
    font-family: codenext;
    font-size: 18px;
    color: red;
    text-align: center;
}

#competitionRewardLabel {
    font-family: codenext;
    white-space: pre-line;
    font-size: 14px;
    color: black;
    text-align: justify;
}
#competitionRatingTitle{
    margin-top: 10px;
    font-family: codenext;
    font-size: 18px;
    color: red;
    text-align: center;
}
.classrating {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    align-items: flex-start;
    margin-top: 15px;
    margin-right: 20px;
    font-family: codenext;
    font-size: 14px;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
}

.RatingLabel{



}

#ratingTable{

    margin-top: 15px;
    margin-right: 20px;
    font-family: codenext;
    font-size: 14px;
    color: black;
    text-align: justify;
}
.employeestatic {

    margin-left: 20px;
    font-family: "Avenir Next bold";
    font-size: 16px;
    color: black;
}

#employeeblock {

    margin-top: -10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 90px;
    background-color: #0FA18D;
    border-radius: 8px;
}

.employeeLabels {
    margin-right: 20px;
    font-family: codenext;
    font-size: 18px;
    color: white;
    text-align: right;
}

#positionblock{
    margin-left: 5px;
    margin-top: -10px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 295px;
    height: 60px;
    background-color: #00B4FF;
    border-radius: 8px;

}

#rateblock {
    margin-left: 10px;
    margin-top: -10px;
    align-items: center;
    align-content: center;
    display: flex;
    justify-content: space-between;
    width: 290px;
    height: 45px;
    background-color: #CBB53A;
    border-radius: 8px;

    text-align: right;

}

#ratelabel {
    margin-right: 20px;
    font-family: "DIN Alternate";
    font-size: 20px;
    color: white;
    text-align: right;

}

#messageblock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    visibility: hidden;
    margin-top: 2px;
    width: 300px;
    height: 35px;
    align-content: center;
    border-radius: 8px;
    text-wrap: normal;
    background-color: #FF4E00;
    text-align: center;
    font-family: "Arial Rounded MT Bold";
    font-family: codenext;
    font-size: 14px;
    color: white;
}

#debtmessage{
    padding-left: 20px;
    text-align: left;
}

#debtValue{
    padding-right: 20px;
    text-align: right;
}


.salaryData {
    margin-right: 0px;
}


.datepicker {
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

#month {
    text-align: center;
    font-family: codenext;
    font-size: 26px;
    margin-top: 20px;
    color: black;
    margin-bottom: 15px;

}

.btn01 {
    font-family: codenext;
    font-size: 16px;
    text-align: center;
    width: 130px;
    height: 60px;
    border-radius: 5px;
    background-color: white;
    color: #808080FF;
    border: solid #808080FF;

}

.btn02 {
    font-family: codenext;
    font-size: 16px;
    text-align: center;
    width: 130px;
    height: 60px;
    border-radius: 5px;
    background-color: white;
    color: black;
    border: solid black;
}

.mainSalaryData {
    display: flex;
    flex-direction: column;
}

.SalaryDataBlockStatic {
    margin-top: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 280px;
    height: 35px;
    border-radius: 8px;
    border: 2px solid #076201 ;
}


.litrsSalaryBonusLabels{
    margin-right: 10px;
    font-family: "Code Next";
    font-size: 20px;
    color: #076201;
    text-align: right;
}



#prepaymentlabel{
    margin-right: 10px;
    font-family: "Code Next";
    font-size: 20px;
    color: #004790;
    text-align: right;
}


.feeAndMinuslabels{
    margin-right: 10px;
    font-family: "Code Next";
    font-size: 20px;
    color: #CC0707;
    text-align: right;
}

#debtlabel{
    margin-right: 10px;
    font-family: "Code Next";
    font-size: 20px;
    color: #A8951A;
    text-align: right;
}

#totalSalarylabel{
    margin-right: 10px;
    font-family: "Code Next";
    font-size: 22px;
    color: #FFFFFF;
    text-align: right;
}


#prepaymentblock {
    margin-top: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 280px;
    height: 35px;
    border-radius: 8px;
    border: 2px solid #004790 ;
}

.feeAndMinusBlock {
    margin-top: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 280px;
    height: 35px;
    border-radius: 8px;
    border: 2px solid #CC0707 ;
}

#debtblock {
    margin-top: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 280px;
    height: 35px;
    border-radius: 8px;
    border: 2px solid #A8951A ;
}



#totalSalaryblock {
    margin-top: 5px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 280px;
    height: 35px;
    border-radius: 8px;
    border: 2px solid;
    background-color: black;
}


.mainSalaryStatic {

    margin-left: 10px;
    font-family: "Marker Felt 1";
    font-size: 18px;
    color: black;
}

#totalSalary {
    margin-left: 10px;
    margin-right: 20px;
    font-family: "Marker Felt 1";
    font-size: 18px;
    color: white;
}

#MainLogBlock {
    margin: 15px;
    width: 320px;
    height: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#AmountAndCommentBlock {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: white;
    width: 320px;
    height: 125px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid black 2px;

}

#CommentBlock{
    text-align: left;
    width: 220px;
    height: 125px;
    padding: 10px;
    font-size: 16px;
    font-family: Avenir;
    color: black;
}

#AmountBlock {

    width: 100px;
    height: 125px;
    display: flex;
    border-right: solid black 2px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#AmountType{
font-family: "DIN Condensed";
font-size: 26px;
color: black;
}


#AmountFromLogs{
    font-family: "DIN Condensed";
    font-size: 20px;
    color: black;
}



#DateLogBlock {
    border: solid black 2px;
    display: block;
    width: 320px;
    height: 40px;
    line-height: 40px;
    background-color: black;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: left;
    color: white;


}

#dateitemLog{
    padding-left: 20px;
}

#UserLogsTitle{
    margin-top: 40px;
    text-align: center;
    font-size: 24px;
    font-family: codenext;
    color: black;
}