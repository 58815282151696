
.page {
    background: #efefef;

}



.Login {
    display: flex;
    height: 900px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    align-content: center;

}




.logoBack {
    background-color: #2b2d30; /* Dark background color */
    width: 100%;
    height: 35vh; /* Increased height to be more than the logo */
    display: flex;
    align-items: center; /* Center the logo vertically */
    justify-content: center; /* Center the logo horizontally */
    position: relative;
}

/* Logo styling */
.logo {
    background: url('../images/logo/logo.png');
    width: 70vh;
    height: 30vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1; /* Ensure the logo is on top of the background */
}


.userInputsblock {
    padding: 10px;
}





.userinput {
    width: 300px;
    height: 40px;
    text-overline-color: black;
    background-color: #f1f1f1;
    border-radius: 5px;
    border: 2px solid #0f131e; /* Ensuring the border is properly styled */
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    transition: border-color 0.3s ease; /* Smooth transition */
}

.userinput:focus {
    border: 2px solid #0f131e; /* Ensuring the border is properly styled */
    outline: none; /* Remove default focus outline */
}


@font-face {
    font-family:"codenext";
    src: url("../files/codenext.otf") format("truetype");
}

.msg {
    padding-top: 30px;
    padding-bottom: 50px;
    display: block;
    color: #de3333;
    height: 30px;

}

.loginBtn {
    width: 100px;
    height: 35px;
    margin-top: 10px;
    border-radius: 5px;
    border-color: white;
    background-color: white;
    color: #0f131e;
    font-family: "codenext";
    font-size: 16px; /* Adjust font size if needed */

}

.loginBtn:hover {
    width: 100px;
    height: 35px;
    margin-top: 10px;
    border-radius: 5px;
    border-color: #0f131e;
    background-color: #0f131e;
    color: white;
    transform: scale(1.1); /* Scale up the checkbox */
    font-family: "codenext";
    font-size: 16px; /* Adjust font size if needed */

}


.checkboxblock {
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.checkboxblock input[type="checkbox"] {
    width: 20px; /* Adjust the size */
    height: 20px; /* Adjust the size */
    transform: scale(1.1); /* Scale up the checkbox */
    margin-right: 10px; /* Add some spacing between the checkbox and the text */
}

.checkboxblock input[type="checkbox"]:hover {
    transform: scale(1.15); /* Scale up the checkbox */
}

#checkboxtext {
    color: black; /* Set the text color to black */
    font-weight: normal; /* Use normal font weight */
    font-size: 16px; /* Adjust font size if needed */
}